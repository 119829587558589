import { createApp } from 'vue'
import App from './App.vue'
import router from '@/assets/js/pageNews.js'
import i18n from '@/assets/js/i18n.js'
import '@/assets/css/index.css'
import '@/assets/css/theme.css'
import Vant from '/node_modules/vant'
import 'vant/lib/index.css'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'icofont/dist/icofont.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import store from '@/assets/store/store.ts'
import chart from './api/chart'
import $ from 'jquery'
import config from '../config.ts'

const app = createApp(App)
app.use(store)
app.use(router)
app.use(chart);
app.use(i18n)
app.use(ElementPlus)
app.use(Vant)
app.use($)

const is_mobile = () => {
  var regex_match =
    /(nokia|iphone|android|motorola|^mot-|softbank|foma|docomo|kddi|up.browser|up.link|htc|dopod|blazer|netfront|helio|hosin|huawei|novarra|CoolPad|webos|techfaith|palmsource|blackberry|alcatel|amoi|ktouch|nexian|samsung|^sam-|s[cg]h|^lge|ericsson|philips|sagem|wellcom|bunjalloo|maui|symbian|smartphone|midp|wap|phone|windows ce|iemobile|^spice|^bird|^zte-|longcos|pantech|gionee|^sie-|portalmmm|jigs browser|hiptop|^benq|haier|^lct|operas*mobi|opera*mini|320x320|240x320|176x220)/i;
  var u = navigator.userAgent;
  if (null == u) {
    return true;
  }
  var result = regex_match.exec(u);
  if (null == result) {
    return false;
  } else {
    return true;
  }
};

if(!is_mobile()){
    console.log(config.Pc_Url);
    window.location.href = config.Pc_Url;
}


// 时间戳转换
function formatTimestamp(timestamp) {
    const date = new Date(Number(timestamp) * 1000);

    // 获取各个时间部分
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // 月份是从0开始的，所以需要加1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // 拼接成所需的格式
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

app.config.globalProperties.$formatTimestamp = formatTimestamp;


app.mount('#app')


// 全局导入ElementPlus Icons
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
