<template>
  <div style="min-height: 100vh; flex: 1;">
    <topNavBar :leftIcon="true" :headerTitle="$t('mine-complaintEmail')" />

    <div class="bg-general m-4 py-3 rounded-[10px] base-shadow">
      <div class="k-text flex justify-center text-center v-text-16 my-4 px-5">
        {{ complaintEmail }}
      </div>
      <div class="flex v-h-36 justify-center">
        <el-button
          size="large"
          class="btn-blue"
          style="border: 0; border-radius: 2.66667vw; font-size: 3.73333vw; width: 19vw ;height: 9.6vw"
          @click="copyContent"
          >{{ $t("public-btn-copy") }}</el-button
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { showToast } from "/node_modules/vant";
import topNavBar from "@/pages/components/common/topNavBar.vue";

const { t } = useI18n();
const complaintEmail = ref("ad@jpkrtradingm.com");

const copyContent = () => {
  const email = complaintEmail.value
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard.writeText(email);
    showToast({
        message: t("systemHint-copySuccess"),
        icon: "success",
    });
  } else {
    const textArea = document.createElement("textarea");
    textArea.value = email;
    textArea.style.position = "fixed";
    textArea.style.opacity = 0;
    document.body.appendChild(textArea);
    textArea.select();
    const successful = document.execCommand('copy');
    if (successful) {
        showToast({
            message: t("systemHint-copySuccess"),
            icon: "success",
        });
    } 
    document.body.removeChild(textArea);
  }
};
</script>

<style></style>
