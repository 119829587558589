const themeFromLocalStorage = localStorage.getItem('theme');
const state = {
    themeColor: themeFromLocalStorage ? themeFromLocalStorage : 'light'
}

const mutations = {
    SET_THEME: (state, val) => {
        localStorage.setItem('theme', val)
        document.documentElement.setAttribute('data-theme', val);
        state.themeColor = val
    }
}

const actions = {
    setTheme({ commit }, theme) {
        commit('SET_THEME', theme)
    }
}

export default {
    state,
    actions,
    mutations
}