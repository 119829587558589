<template>
  <div id="profitLoss">
    <div class="profit-loss v-rounded-10 v-mt-20 v-mb-10 v-p-16 base-shadow">
      <div v-if="curTab !== 2">
        <div class="k-text text-center v-mb-20">
          {{ $t("order-profit_loss") }}
        </div>
        <div class="text-center font-bold text-xl v-mt-10 v-mb-20">
          <span
            :class="
              report?.profit && report?.profit > 0
                ? 'text-kline-up'
                : 'text-kline-down'
            "
            >{{ report?.profit }}</span
          >
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12">
            {{ $t("public-text-balance") }}
          </div>
          <div class="sub-value v-text-16 font-bold">
            {{ report?.balance_total || 0 }}
          </div>
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12">
            {{ $t("order-current_margin") }}
          </div>
          <div class="sub-value v-text-16 font-bold">
            {{ report?.used_margin }}
          </div>
        </div>
        <!-- <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12">
            {{ $t("order-risk_rate") }}
          </div>
          <div class="sub-value v-text-16 font-bold">{{ report?.profit }}%</div>
        </div> -->
      </div>

      <div v-else>
        <div class="k-text text-center v-mb-20">
          {{ $t("order-total_profit_loss") }}
        </div>
        <div class="text-center font-bold text-xl v-mt-10 v-mb-20">
          <span
            :class="report?.profit > 0 ? 'text-kline-up' : 'text-kline-down'"
            >{{ report?.profit }}</span
          >
        </div>
        <div class="flex items-center justify-between v-mt-2">
          <div class="sub-text v-text-12">
            {{ $t("public-text-balance") }}
          </div>
          <div class="sub-value v-text-16 font-bold">
            {{ report?.balance_total || 0 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue";

const props = defineProps({
  curTab: {
    type: Number,
    default: 0,
  },
  ReportData: {
    type: Object,
  },
});

const netValue = ref("3707.22");
const isProfit = computed(() => netValue.value > 0);
const isNetProfit = computed(() => allNetValue.value > 0);
const allNetValue = ref("-480705207025.923");
const report = ref();

watch(
  () => props.ReportData,
  (data) => {
    if (data) {
      report.value = data;
    }
  },
  { immediate: true }
);
</script>

<style scoped></style>
