import { createStore } from 'vuex'
import getters from './getters.js'
import VuexPersist from 'vuex-persistedstate';

// modules
import user from './user/index.ts'
import theme from './theme/theme.js'

const store = createStore({
    modules: {
        user,
        theme,
    },
    getters,
    plugins:[
        VuexPersist({
            storage:window.localStorage
        })
    ]
})

export default store


