<template>
    <div style="min-height: 100vh; flex: 1">
        <TopNavBar :leftIcon="false" :headerTitle="$t('tabbar-news')" />
        <van-search v-model="NewName" placeholder="请输入搜索关键词" @search="ChangeName()" />

        <div class="v-px-12">
            <div class="flex justify-center v-py-16" v-if="loading">
                <van-loading type="circular" size="24px">{{
                    $t("public-text-loading")
                    }}</van-loading>
            </div>

            <div v-else>
                <van-pull-refresh v-model="loadingRefresh" :head-height="80" @refresh="onRefresh"
                    :pulling-text="$t('public-text-scroll_down_refresh')"
                    :loosing-text="$t('public-text-loose-to-refresh')" :loading-text="$t('public-text-loading')">
                    <div v-if="newsList.length == 0">
                        <noRecord />
                    </div>

                    <div id="list" v-else>
                        <div v-for="(item, index) in newsList" :key="index"
                            class="list-main v-rounded-base base-shadow v-mt-10 v-p-10 flex justify-between v-h-100"
                            @click="openItem(item)">
                            <div class="flex flex-col justify-between v-w-172">
                                <div class="v-text-12 font-bold title truncate">
                                    {{ item.title }}
                                </div>
                                <div class="flex-1 content k-text">{{ item.desc }}</div>
                                <div class="date v-text-12">{{ item.time }}</div>
                            </div>
                            <img :src="require(`@/assets/image/${item.image}`)"
                                class="van-image v-w-130 v-rounded-4 overflow-hidden" />
                        </div>
                    </div>
                </van-pull-refresh>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { store } from "@/assets/store/data.js";
import TopNavBar from './components/common/topNavBar.vue';
import { useRouter } from 'vue-router';

const newsList = ref([]);
const loading = ref(true)
const loadingRefresh = ref(false);
const NewName = ref('')
const router = useRouter();

onMounted(async() => {
    newsList.value = store.value.newsList;
    loading.value = false;
})

const openItem = (item) => {
    router.push({
        name: "NewsDetails",
        query: { id: item.id },
    });
};

// 上拉刷新
const onRefresh = async() => {
    newsList.value = store.value.newsList;
    loadingRefresh.value = false;
}

// 搜索
const ChangeName = async() => {
    if(NewName.value == 'test'){
        localStorage.setItem("Pageflag",true)
        router.push('/')
    }else{
        console.log(1)
    }
}
</script>