<template>
  <div id="order" style="min-height: 100vh; flex: 1">
    <van-pull-refresh
      v-model="loading"
      :head-height="80"
      @refresh="onRefresh"
      :disabled="isShow"
      :pulling-text="$t('public-text-scroll_down_refresh')"
      :loosing-text="$t('public-text-loose-to-refresh')"
      :loading-text="$t('public-text-loading')"
      class="v-min-h-screen px-[2vw] pt-[8vw] v-pb-100"
    >
      <!-- 上方 按钮栏 -->
      <el-scrollbar :noresize="false" aria-orientation="vertical">
        <div class="text-color-7 flex">
          <el-button
            class="v-border-0 customButton"
            :class="selectedTab == 0 ? 'btn-blue' : 'btn-non-bg'"
            round
            style="font-size: 3.4vw; border-radius: 8vw"
            @click="setTab(0)"
            >{{ $t("order-position_holding") }}</el-button
          >
          <el-button
            class="v-border-0 customButton"
            :class="selectedTab == 3 ? 'btn-blue' : 'btn-non-bg'"
            round
            style="font-size: 3.4vw; border-radius: 8vw"
            @click="setTab(3)"
            >{{ $t("trade-quick_trade") }}</el-button
          >
          <el-button
            class="v-border-0 customButton"
            :class="selectedTab == 1 ? 'btn-blue' : 'btn-non-bg'"
            style="font-size: 3.4vw; border-radius: 8vw"
            round
            @click="setTab(1)"
            >{{ $t("order-pending_orders") }}</el-button
          >
          <el-button
            class="v-border-0 customButton"
            :class="selectedTab == 2 ? 'btn-blue' : 'btn-non-bg'"
            style="font-size: 3.4vw; border-radius: 8vw"
            round
            @click="setTab(2)"
            >{{ $t("order-history") }}</el-button
          >
        </div>
      </el-scrollbar>

      <!-- Position holding 显示内容 -->
      <div v-if="selectedTab == 0">
        <ProfitnLoss :curTab="0" :ReportData="ReportData" />
        <HoldingList :orderStatus="0" @toggle-popup="togglePopup" />
      </div>

      <!-- Quick Trade 秒合约 -->
      <div v-if="selectedTab == 3">
        <ProfitnLoss :curTab="3" :ReportData="ReportData" />
        <HoldingList :orderStatus="3" @toggle-popup="togglePopup" />
      </div>

      <!-- Pending orders 显示内容 -->
      <div v-if="selectedTab == 1">
        <ProfitnLoss :curTab="1" :ReportData="ReportData" />
        <HoldingList :orderStatus="1" @toggle-popup="togglePopup" />
      </div>

      <!-- Order History 显示内容 -->
      <div v-if="selectedTab == 2">
        <ProfitnLoss :curTab="2" :ReportData="ReportData" />
        <HoldingList :orderStatus="2" @toggle-popup="togglePopup" />
      </div>
    </van-pull-refresh>
    <tabBar />
  </div>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
// import { store } from "@/assets/store/data.js";
import { useStore } from "vuex";
import tabBar from "@/pages/components/common/tab-bar.vue";
import ProfitnLoss from "@/pages/tabBarPages/order/profitLoss.vue";
import HoldingList from "@/pages/tabBarPages/order/holdingList.vue";
import { List } from "vant";

const store = useStore();
const loading = ref(false);
const isShow = ref(false);
const OrderData = ref();
const ReportData = ref();
const ListData = ref([]);

const togglePopup = (status) => {
  isShow.value = status;
};

//顶部下拉刷新
const onRefresh = () => {
  setTimeout(() => {
    loading.value = false;
  }, 1000);
};

const selectedTab = ref(0);
const setTab = (tabNumber) => {
  selectedTab.value = tabNumber;
};
let type = "";

watch(
  () => selectedTab.value,
  async (newTab) => {
    switch (newTab) {
      case 0:
        type = "hold";
        break;
      case 1:
        type = "pending";
        break;
      case 2:
        type = "history";
        break;
      case 3:
        type = "mic";
        break;
      default:
        break;
    }

    await GetData();
  },
  { immediate: true }
);

async function GetData(){
    // 获取订单
    try{
        OrderData.value = await store.dispatch("GetOrderList", {
            data: { page: 1, per_page: 6, type: type },
        });
        ReportData.value = OrderData.value.report;
    }catch(e){
        console.log(e)
    }
}

const IntervalID = ref()

onMounted(async() =>{
    IntervalID.value = setInterval(async () => {
        await GetData()
    }, 1000);
})

onBeforeUnmount(() => {
    clearInterval(IntervalID.value)
})
</script>

<style scoped>
.customButton {
  /* width: 30vw; */
  min-width: 25vw;
  width: fit-content;
  height: 10vw;
  border: 0;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
}
/* .custom-button-active {
  width: 30vw;
  height: 10vw;
  border: 0;
  display: flex;
  background: #0166fc;
}
.custom-button-active:hover {
  background: #0166fc;
} */

/* .custom-button-inactive {
  width: 30vw;
  height: 10vw;
  border: 0;
  display: flex;
  color: #cbcbcb;
  background: none;
}
.custom-button-inactive:hover {
  background: none;
} */
</style>
