export default {
  "app-title": "FBS",
  "app-title-2": "Non à la vente",
  "app-title-language": "Langue",
  "title-detail": "Détail",
  "title-welcome_to_use": "Bienvenue à utiliser",

  //tabBar
  "tabbar-market": "Marché",
  "tabbar-order": "Commande",
  "tabbar-news": "Actualités",
  "tabbar-mine": "Mon espace",
  "public-night_mode": "Mode nuit",
  "public-noRecord": "Pas de données",
  "public-text-cancel": "Annuler",
  "public-text-confirm": "Confirmer",
  "public-text-balance": "Solde",
  "public-text-date": "Date",
  "public-text-completed": "Terminé",
  "public-text-invalid": "Invalide",
  "public-text-unchecked": "Non vérifié",
  "public-text-remarks": "Remarques",
  "public-text-loading": "Chargement",
  "public-text-loose-to-refresh": "Relâchez pour rafraîchir...",
  "public-text-buy": "Acheter",
  "public-text-sell": "Vendre",
  "public-text-close": "Fermer",
  "public-text-lots_1": "Pièces",
  "public-text-lots": "Pièces",
  "public-text-available": "Disponible",
  "public-text-freeze": "Gelé",
  "public-text-scroll_down_refresh":
    "Faites défiler vers le bas pour rafraîchir...",
  "public-btn-submit": "Soumettre",
  "public-btn-add": "Ajouter",
  "public-btn-copy": "Copier",
  "public-btn-delete": "Supprimer",
  "public-btn-copyLink": "Copier le lien d'invitation",
  "public-btn-send": "Envoyer",
  "public-btn-download": "TÉLÉCHARGER",
  "public-btn-resetPassword": "Réinitialiser le mot de passe",
  "public-btn-confirmation": "Confirmation",
  "public-btn-accept": "Accepter",
  "public-enterEmail": "Veuillez entrer votre adresse e-mail",
  "public-password": "Mot de passe",
  "public-retypePassword": "Retapez le mot de passe",
  "public-inviteCode": "Code d'invitation",
  "public-verifyCode": "Code de vérification",
  "login-newUser": "Nouvel utilisateur ?",
  "login-joinNow": "Rejoignez-nous maintenant",
  "login-forgotPassword": "Mot de passe oublié",
  "login-btn-login": "Connexion",
  "login-btn-demoAcc":
    "Aucun compte requis, créez un compte démo pour commencer à trader dès maintenant !",
  "login-enterEmail": "Veuillez entrer votre adresse e-mail",
  "login-enterPassword": "Veuillez entrer votre mot de passe",
  "regsiter-btn-register": "S'inscrire",
  "regsiter-enterInviteCode": "Veuillez entrer le code d'invitation",
  "regsiter-enterVerityCode": "Veuillez entrer le code de vérification",
  "logout-title": "Déconnexion",
  "systemHint-reset_successful": "Réinitialisation réussie",
  "systemHint-incorrectVerityCode": "Code de vérification incorrect",
  "systemHint-passwordsDoNotMatch": "Les mots de passe ne correspondent pas",
  "systemHint-password_error": "Erreur de mot de passe",
  "systemHint-login_connot_find_user": "Utilisateur non trouvé",
  "systemHint-copySuccess": "Copie réussie",
  "systemHint-plsCompleted": "Veuillez compléter",
  "systemHint-pwdInconsistent": "Mot de passe incohérent",
  "systemHint-incorrectVerifyCode": "Code de vérification incorrect",
  "systemHint-changeSuccess": "Changement réussi",
  "systemHint-plsSubmit": "Veuillez soumettre toutes les informations",
  "systemHint-binb-bank-not-open-yet":
    "Le retrait de ce montant de monnaie n'est pas encore ouvert",
  "systemHint-plsFillIn": "Veuillez remplir toutes les informations",
  "systemHint-requestSuccessful": "Demande réussie",
  "systemHint-operation_successful": "Opération réussie",
  "systemHint-set_successful": "Configuration réussie",
  "systemHint-missing_params-or-val-error":
    "Paramètres manquants ou erreur de valeur",
  "systemHint-limit_trade_price_must_greater":
    "Le prix limite de la transaction doit être supérieur à 0",
  "systemHint-buy-stop_loss_price_higher_than_opening_price":
    "Le prix de stop-loss pour l'achat (long) ne doit pas être supérieur au prix d'ouverture",
  "systemHint-buy-stop_profit_price_higher_than_opening_price":
    "Le prix de stop-profit pour l'achat (long) ne doit pas être supérieur au prix d'ouverture",
  "systemHint-sell-stop_loss_price_lower_than_opening_price":
    "Le prix de stop-loss pour la vente (court) ne doit pas être inférieur au prix d'ouverture",
  "systemHint-sell-stop_profit_price_lower_than_opening_price":
    "Le prix de stop-profit pour la vente (court) ne doit pas être inférieur au prix d'ouverture",
  "systemHint-code_send_succesful": "Code de vérification envoyé avec succès",
  "systemHint-pls_register_login_real_account":
    "Veuillez vous inscrire ou vous connecter à un compte réel",
  "systemHint-successful_exit": "Déconnexion réussie",
  "popupHint-set_up_collection_account":
    "Veuillez d'abord configurer un compte de réception",
  "popupHint-confirm_to_delete": "Êtes-vous sûr de vouloir supprimer ?",
  "popupHint-whether_return_it": "S'il faut le retourner",
  "mine-availableAssets": "Actifs disponibles",
  "mine-account_details": "Détails du compte",
  "mine-deposit": "Dépôt",
  "mine-withdraw": "Retrait",
  "mine-billDetails": "Détails de la facture",
  "mine-demo_return_simulated_account": "Revenir au compte simulé",
  "mine-wallet": "Portefeuille",
  "mine-verifyName": "Vérification d'identité",
  "mine-inviteFriend": "Inviter des amis",
  "mine-changePassword": "Changer le mot de passe",
  "mine-onlineSerive": "Service en ligne",
  "mine-complaintEmail": "Email de réclamation",
  "mine-announcement": "Annonce",
  "mine-establishment": "Établissement",
  "mine-other": "Autre",
  "mine-change_theme": "Changer le thème",
  "dialog-title": "Remarque",
  "dialog-logout-conetnt": "Êtes-vous sûr de vouloir vous déconnecter ?",
  "announce-text":
    "Pour des raisons politiques, les services ne sont pas fournis en Corée du Nord, en Israël, en Chine, à Vanuatu et à Cuba.",
  "announce-text-demo": "(FBS Réservé à des fins de test)",
  "verify-text-upload":
    "Veuillez télécharger l'avers et le revers de votre passeport/pièce d'identité",
  "verify-front": "Avers",
  "verify-front-desc":
    "Téléchargez l'avers de votre passeport/pièce d'identité",
  "verify-back": "Revers",
  "verify-back-desc":
    "Téléchargez le revers de votre passeport/pièce d'identité",
  "verify-text-info": "Veuillez compléter vos informations personnelles",
  "verify-name": "Nom",
  "verify-enterName": "Veuillez entrer votre nom",
  "verify-passportId": "Numéro de passeport/pièce d'identité",
  "verify-enterPassportId":
    "Veuillez entrer votre numéro de passeport/pièce d'identité",
  "verify-infoAuth": "Authentification",
  "wallet-bind-bank": "Lier une carte bancaire",
  "wallet-bind-currency-address": "Lier une adresse de monnaie numérique",
  "bind-bank-name": "Nom de la banque",
  "bind-bank-address": "Adresse de la banque",
  "bind-bank-swift": "SWIFT",
  "bind-bank-payee": "Bénéficiaire",
  "bind-bank-payee-account": "Compte du bénéficiaire",
  "bind-currency": "Monnaie",
  "bind-wallet-address": "Adresse du portefeuille",
  "deposit-digitai-currency": "Monnaie numérique",
  "deposit-bank-card": "Carte bancaire",
  "deposit-select_network": "Sélectionner le réseau",
  "deposit-coin-number": "Nombre de pièces",
  "deposit-coin-placeholder": "Veuillez entrer le nombre de pièces à déposer",
  "doposit-upload-cert": "Télécharger le certificat",
  "deposit-record": "Historique des dépôts",
  "deposit-recharge_amount": "Montant du dépôt",
  "deposit-recharge-placeholder": "Veuillez entrer le montant du dépôt",
  "deposit-recharge_type": "Type",
  "deposit-recharge_unit": "Unité",
  "deposit-recharge_status": "Statut",
  "deposit-receipt_currency": "Monnaie reçue",
  "withdraw-coin_address": "Adresse de retrait des pièces",
  "withdraw-coin_amount": "Montant",
  "withdraw-handling_fee": "Frais de traitement",
  "withdraw-expected": "Montant prévu à être crédité",
  "withdraw-btn-submit": "Demander un retrait",
  "withdraw-coin_balance": "Solde des pièces",
  "withdraw-address_error": "Adresse de retrait incorrecte",
  "withdraw-amount_error": "Montant de retrait incorrect",
  "withdraw-error": "Erreur de traitement",
  "order-position_holding": "Position en cours",
  "order-pending_orders": "Ordres en attente",
  "order-history": "Historique",
  "order-profit_loss": "Profit et perte",
  "order-current_margin": "Marge actuelle",
  "order-risk_rate": "Taux de risque",
  "order-order_id": "ID de commande",
  "order-total_profit_loss": "Profit et perte totaux",
  "order-order_details": "Détails de la commande",
  "order-margin": "Marge",
  "order-take_profit": "Prendre des bénéfices",
  "order-set_loss": "Définir la perte",
  "order-set_TPSL": "Définir TP/SL",
  "order-close_position": "Fermer la position",
  "order-cancel_order": "Annuler la commande",
  "order-select_period": "Sélectionner la période",
  "order-btn_rise": "Monter",
  "order-btn_fall": "Descendre",
  "order-estimated_return": "Retour estimé",
  "order-purchase_amount": "Montant de l'achat",
  "search-search_product": "Rechercher un produit",
  "search-search_result": "Résultats de recherche",
  "trade-market_price": "Prix du marché",
  "trade-price": "Prix",
  "trade-quick_trade": "Commerce rapide",
  "trade-please_enter_price": "Veuillez entrer le prix",
  "trade-each_lots": "Chaque lot",
  "trade-estimated_handling_fee": "Frais de traitement estimés",
  "trade-estimated_margin": "Marge estimée",
  "trade-lots_text": "Lots (pas : 0,01)",
  "trade-order_page": "Page de commande",
  "trade-your_order_has_been_confirmed": "Votre commande a été confirmée",
  "trade-multiplier": "Multiplicateur",
  "accHistory-financial_records": "Documents financiers",
  "accHistory-records": "Documents",

  Res_Msg1: "Veuillez entrer votre adresse e-mail",
  Res_Msg2: "Format d'e-mail invalide",
  Res_Msg3: "Veuillez entrer votre mot de passe",
  Res_Msg4:
    "Le mot de passe et la confirmation du mot de passe ne correspondent pas",
  Res_Msg5: "Le mot de passe doit contenir au moins 6 caractères",
  Res_Msg6: "Code de vérification incorrect",
  Res_Msg7: "Veuillez entrer le code de vérification",
  Res_su: "Inscription réussie",
  Login_su: "Connexion réussie",
  Loading: "Plus de données",
  SDealCont_TopName: "Livraison",
  SDealCont_L1: "Prix d'achat",
  SDealCont_L2: "Prix actuel",
  SDealCont_L3: "Direction",
  SDealCont_L4: "Quantité",
  SDealCont_L5: "Heure de livraison",
  SDealCont_btn: "Fermer",
  SDealCont_L6: "Acheter long",
  SDealCont_L7: "Vendre court",
  SDealCont_L8: "Acheter",
  SDealCont_L9: "Vendre",
  "verify-verified": "Vérification réussie",
  "verify-notVerified": "Échec de la vérification",
  "加载中...": "Chargement en cours...",
  "分时":"Temps",
  "登入密碼": "Veuillez entrer le mot de passe de connexion",
    "登入密碼不能为空": "Le mot de passe de connexion ne peut pas être vide"
};
