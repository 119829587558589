<template>
    <div id="tabbar" class="relative z-[9999] footer bgFooter">
        <van-tabbar class="tabbar" route v-model="active" @change="changeIndex" fixed safe-area-inset-bottom>
            <!-- active-color="#1194F7"
      inactive-color="#cecece" -->
            <van-tabbar-item name="home" to="/">
                <template #icon="props">
                    <el-icon :color="'#fff'" class="tab-bar-icon"
                        :class="[props.active ? 'bg-active customCss' : 'customCss']">
                        <component :is="'Histogram'" style="box-sizing: content-box; height: 4.55vw; width: 4.55vw" />
                    </el-icon>
                </template>

                <span class="v-text-12" :class="[active === '/' ? 'active' : '']">
                    {{$t("tabbar-market")}}
                </span>

            </van-tabbar-item>

            <van-tabbar-item name="order" to="/pages/tabBarPages/order/orderIndex" @click="handleOrderClick">
                <span class="v-text-12" :class="[active === 'order' ? 'active' : '']">{{
                    $t("tabbar-order")
                    }}</span>
                <template #icon="props">
                    <el-icon :color="'#fff'" class="tab-bar-icon"
                        :class="[props.active ? 'bg-active customCss' : 'customCss']">
                        <component :is="'Menu'" style="box-sizing: content-box; height: 4.55vw; width: 4.55vw" />
                    </el-icon>
                </template>
            </van-tabbar-item>

            <van-tabbar-item name="trade" to="/pages/tabBarPages/tradeIndex">
                <span class="v-text-12" :class="[active === 'trade' ? 'active' : '']"></span>
                <template #icon>
                    <el-icon :color="'#fff'" :class="'customTradeStyle'">
                        <component :is="'Switch'" style="box-sizing: content-box; height: 7vw; width: 7vw" />
                    </el-icon>
                </template>
            </van-tabbar-item>

            <van-tabbar-item name="news" to="/pages/tabBarPages/newsIndex">
                <span class="v-text-12" :class="[active === 'news' ? 'active' : '']">{{
                    $t("tabbar-news")
                    }}</span>
                <template #icon="props">
                    <el-icon :color="'#fff'" class="tab-bar-icon"
                        :class="[props.active ? 'bg-active customCss' : 'customCss']">
                        <component :is="'SortDown'" style="box-sizing: content-box; height: 4.55vw; width: 4.55vw" />
                    </el-icon>
                </template>
            </van-tabbar-item>

            <van-tabbar-item name="mine" to="/pages/tabBarPages/mine/mineIndex" @click="handleMineClick">
                <span class="v-text-12" :class="[active === 'news' ? 'active' : '']">{{
                    $t("tabbar-mine")
                    }}</span>
                <template #icon="props">
                    <el-icon :color="'#fff'" class="tab-bar-icon"
                        :class="[props.active ? 'bg-active customCss' : 'customCss']">
                        <component :is="'User'" style="box-sizing: content-box; height: 4.55vw; width: 4.55vw" />
                    </el-icon>
                </template>
            </van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script setup>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

const active = ref("/");
const route = useRoute();
const router = useRouter();
const store = useStore();

const changeIndex = (name) => {
  active.value = name;
};

const handleOrderClick = () => {
  router.push("/pages/tabBarPages/order/orderIndex");
};

const handleMineClick = () => {
  const token = localStorage.getItem("token");
  if (token) {
    router.push("/pages/tabBarPages/mine/mineIndex");
  } else {
    router.push("/pages/login/loginIndex");
  }
};

if (route.path === "/") {
  active.value = "home";
} else if (route.path === "/pages/tabBarPages/order/orderIndex") {
  active.value = "order";
} else if (route.path.indexOf("/pages/tabBarPages/tradeIndex") !== -1) {
  active.value = "trade";
} else if (route.path.indexOf("/pages/tabBarPages/newsIndex") !== -1) {
  active.value = "news";
} else if (route.path === "/pages/tabBarPages/mine/mineIndex") {
  active.value = "mine";
}

watch(
  () => route.path,
  (vn) => {
    if (route.path === "/") {
      active.value = "home";
    } else if (route.path === "/pages/tabBarPages/order/orderIndex") {
      active.value = "order";
    } else if (route.path.indexOf("/pages/tabBarPages/tradeIndex") !== -1) {
      active.value = "trade";
    } else if (route.path.indexOf("/pages/tabBarPages/newsIndex") !== -1) {
      active.value = "news";
    } else if (route.path === "/pages/tabBarPages/mine/mineIndex") {
      active.value = "mine";
    }
  }
);
</script>

<style scoped>
.customCss {
  width: 8vw;
  height: 8vw;
  padding: 1.333vw;
  border-radius: 2.55vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #cecece;
}

.bg-active {
  /* background-color: #0166fc; */
}

.customTradeStyle {
  width: 13.3333vw;
  height: 13.3333vw;
  background-color: #0166fc;
  padding: 1.333vw;
  border-radius: 50%;
  bottom: 6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bg-active.customCss{
    background: #0166fc;
}
</style>
