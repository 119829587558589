// 随机生成验证码文本函数
function generateCaptchaCode(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

// 随机生成颜色函数
function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const drawCaptcha = (dom, length) => {
  const canvas = document.querySelector(dom);
  const ctx = canvas.getContext("2d");

  // 清除画布
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // 绘制背景
  ctx.fillStyle = "#fff";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // 设置字体
  ctx.font = "60px Arial";
  ctx.fillStyle = "black";

  // 生成验证码
  const captchaCode = generateCaptchaCode(length);

  // 获取文本宽度
  const textWidth = ctx.measureText(captchaCode).width;

  // 计算文本在 Canvas 上居中的位置
  const xPosition = (canvas.width - textWidth) / 2;
  const yPosition = (canvas.height + 60) / 2; // 60 是字体大小，这里假设文本基线在垂直中心
    
  // 绘制验证码文本
  ctx.fillText(captchaCode, xPosition, yPosition);

  // 添加干扰线
  for (let i = 0; i < 5; i++) {
    // 生成5条干扰线
    ctx.strokeStyle = getRandomColor();
    ctx.beginPath();
    ctx.moveTo(Math.random() * canvas.width, Math.random() * canvas.height);
    ctx.lineTo(Math.random() * canvas.width, Math.random() * canvas.height);
    ctx.lineWidth = 3;
    ctx.stroke();
  }

  // 返回验证码文本
  return captchaCode;
};
