import Server from "@/Axios/index.ts";

// 登录
async function Login(data) {
  return await Server.post("/api/login/login", data);
}

// 注册
async function Reg(data) {
  await Server.post("/api/login/register", data);
}

// 试玩注册
async function DemoReg(){
    return await Server.get("/api/login/demo");
}

// 获取用户数据
async function GetUserData() {
  return await Server.get("/api/user/info");
}

// 获取订单
async function GetUserOrderList(data) {
  return await Server.post("/api/mic/orderList", data);
}

// 获取所有产品
async function GetAreaData(data) {
  return await Server.post("api/product/list", data, { cancelToken: data.cancelTokenSource });
}

// 获取合约配置
async function GetContData(data) {
  return await Server.post("/api/lever/initData", data);
}

// 秒合约买入卖出
async function SDealCont(data) {
  return await Server.post("/api/mic/orderOpen", data);
}

// 平仓挂单买入卖出
async function DealCont(data) {
  await Server.post("/api/lever/orderOpen", data);
}

// 获取秒合约详细
async function GetSDealOrder(data) {
  return await Server.post("/api/mic/getOrderDetail", data);
}

// 获取新闻列表
async function GetNewList() {
  return await Server.post("/api/common/getNewsList");
}

// 获取新闻详情
async function GetNewDetail(data) {
  return await Server.post("/api/common/getNewsDetail", data);
}

// 获取资金记录
async function GetMoneyRecord(data) {
  return await Server.post("/api/wallet/gerMarkList", data);
}

// 获取数字充值货币的币种
async function GetRechargeSymbolList() {
  return await Server.post("/api/wallet/getRechargeSymbolList");
}

// 获取数字提现货币的币种
async function GetWithdrawSymbolList() {
  return await Server.post("/api/wallet/getWithdrawSymbolList");
}

// 获取提现货币
async function GetUsdt() {
  return await Server.post("/api/wallet/getWithdrawSymbolList");
}

// 产品索引搜索
async function GetDataByType(data) {
  return await Server.post("/api/product/autocomplete", data);
}

// 获取订单列表
async function GetOrderList(data) {
  return await Server.post("/api/lever/orderList", data);
}

// 退出登录
async function LogOut() {
  await Server.post("/api/user/logout");
}

// 获取修改密码的验证码
async function GetVerificationCode(data) {
  return await Server.post("/api/ems/send", data);
}

// 修改验密码
async function UpPassWord(data) {
  await Server.post("/api/user/changePasswd", data);
}

// 获取分享地址
async function GetShare() {
  return await Server.post("/api/user/getShare");
}

// 修改 止损止盈
async function UpLossProfit(data){
    await Server.post('/api/lever/setStopPrice',data);
}

// 合约平仓
async function Pingc(data) {
    await Server.post('/api/lever/closeTrade',data)
}

// 添加银行卡
async function AddBank(data){
    await Server.post("/wallet/addWallet",data)
}

// 获取客服链接
async function GetServiceUrl() {
    return Server.post("/api/common/get_customer_url")
}

export default {
    AddBank,
  Login,
  Reg,
  GetUserData,
  GetUserOrderList,
  GetAreaData,
  GetContData,
  SDealCont,
  DealCont,
  GetSDealOrder,
  GetNewList,
  GetNewDetail,
  GetMoneyRecord,
  GetRechargeSymbolList,
  GetWithdrawSymbolList,
  GetUsdt,
  GetDataByType,
  GetOrderList,
  LogOut,
  GetVerificationCode,
  UpPassWord,
  GetShare,
  UpLossProfit,
  Pingc,
  DemoReg,
  GetServiceUrl
};
