<template>
    <div style="min-height: 100vh; flex: 1">
        <topNavBar :leftIcon="true" :headerTitle="$t('mine-account_details')" />

        <van-pull-refresh v-model="loading" :pulling-text="$t('public-text-scroll_down_refresh')"
            :loosing-text="$t('public-text-loose-to-refresh')" :loading-text="$t('public-text-loading')">
            <div class="bg-main v-p-16">
                <div class="k-text font-bold text-[20px] v-mb-10">USDT</div>
                <div class="flex items-center">
                    <div class="flex-1">
                        <div class="text-color-7 v-text-12">
                            {{ $t("public-text-available") }}
                        </div>
                        <div class="text-kline-up v-text-16">{{ userInfo.balance||0 }}</div>
                    </div>
                    <div class="flex-1">
                        <div class="text-color-7 v-text-12">
                            {{ $t("public-text-freeze") }}
                        </div>
                        <div class="k-text v-text-16">{{ userInfo.lock_balance||0 }}</div>
                    </div>
                </div>
            </div>


            <div class="text-color-7 v-text-12 v-m-8">
                <div class="v-mb-10 k-text v-text-14 v-pl-10">
                    {{ $t("accHistory-financial_records") }}
                </div>
                <van-list v-model:loading="Onloading" :finished="finished" :finished-text="$t('Loading')" @load="onLoad"
                    style="min-height: 80vh;margin-bottom: 80px;" :loading-text="$t('加载中...')">
                    <van-cell v-for="(item, index) in financialRecords" :key="index">
                        <div class=" bg-general v-rounded-8" style="text-align: left;font-size: 12px;">
                            <div class="flex items-center justify-between">
                                <div>
                                    <span class="v-pr-10">{{ $t("withdraw-coin_amount") }}</span>
                                    <span class="k-text">{{ item.amount }}</span>
                                </div>
                                <div>
                                    <span class="v-pr-10">{{ $t("accHistory-records") }}</span>
                                    <span class="k-text">{{ item.content }}</span>
                                </div>
                            </div>

                            <div class="v-mt-8">
                                <span class="v-pr-10">{{ $t("public-text-date") }}</span>
                                <span class="k-text">{{ item.ctime }}</span>
                            </div>
                        </div>

                    </van-cell>

                </van-list>
                <div v-if="financialRecords.length == 0">
                    <noRecord />
                </div>
            </div>


        </van-pull-refresh>
    </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { store } from "@/assets/store/data.js";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import noRecord from "@/pages/components/common/noRecord.vue";

const { t } = useI18n();
const router = useRouter();
const storeA = useStore();
const userInfo = computed(() => storeA.getters.GetInfoData);
const financialRecords = ref([]);
const Onloading = ref(false);
const finished = ref(false);
const loading = ref(false);
let Pageindex = 1;

async function GetMoneyRecord(){
    loading.value = true
    const arr = await storeA.dispatch('GetMoneyRecord', {
        data: {
            page: Pageindex,
            size: 10,
        }
    })

    if(arr.list.length < 10){
        finished.value = true;
    }

    financialRecords.value.push(...arr.list)
    loading.value = false
}

async function onLoad() {
    Pageindex++;
    await GetMoneyRecord();
    Onloading.value = false;
}

onMounted(async() =>{
    await GetMoneyRecord()
})

</script>

<style scoped></style>
