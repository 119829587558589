<template>
    <div style="min-height: 100vh; flex: 1">
        <div class="v-pb-100">
            <topNavBar :leftIcon="false" :headerTitle="$t('tabbar-news')" />

            <div class="v-px-12">
                <div class="flex justify-center v-py-16" v-if="loading">
                    <van-loading type="circular" size="24px">{{
                        $t("public-text-loading")
                        }}</van-loading>
                </div>

                <div v-else>
                    <van-pull-refresh v-model="loadingRefresh" :head-height="80" @refresh="onRefresh"
                        :pulling-text="$t('public-text-scroll_down_refresh')"
                        :loosing-text="$t('public-text-loose-to-refresh')" :loading-text="$t('public-text-loading')">
                        <div v-if="newsList.length == 0">
                            <noRecord />
                        </div>

                        <div id="list" v-else>
                            <div v-for="(item, index) in newsList" :key="index"
                                class="list-main v-rounded-base base-shadow v-mt-10 v-p-10 flex justify-between v-h-100"
                                @click="openItem(item)">
                                <div class="flex flex-col justify-between v-w-172">
                                    <div class="v-text-12 font-bold title truncate">
                                        {{ item.title }}
                                    </div>
                                    <div class="flex-1 content k-text" v-html="decodedHtml(item.content)"></div>
                                    <div class="date v-text-12">{{ item.create_time }}</div>
                                </div>
                                <!-- :src="require(item.icon)" -->
                                <img :src="item.logo" class="van-image v-w-130 v-rounded-4 overflow-hidden"
                                    style="height: 4em;" />
                            </div>
                        </div>
                    </van-pull-refresh>
                </div>
            </div>
        </div>
        <tabBar />
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import tabBar from "@/pages/components/common/tab-bar.vue";
import noRecord from "@/pages/components/common/noRecord.vue";
import { useStore } from "vuex";

const router = useRouter();
const newsList = ref([]);
const loading = ref(true);
const loadingRefresh = ref(false);
const store = useStore();

const onRefresh = async() => {
    await fetchNews()
    loadingRefresh.value = false;
};

// 获取新闻
const fetchNews = async () => {
    newsList.value = await store.dispatch("GetNewList")
    loading.value = false;
};

onMounted(async() => {
    await fetchNews();
});

const getImageUrl = (imageName) => {
    let url = require(`@/assets/image/${imageName}`);
    if (imageName) return url;
};

const decodedHtml = (html) => {
    // 将实体解码为 HTML
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(html, "text/html").body.textContent;
    return decodedString;
}

const openItem = (item) => {
    router.push({
        name: "NewsDetails",
        query: { id: item.id },
    });
};
</script>

<style scoped>
.van-image {
    position: relative;
    display: inline-block;
    align-content: center;
}

.content {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    word-break: break-all;
    line-height: 5.33333vw;
    max-height: 10.66667vw;
}
</style>
