import hk from '@/assets/language/hk' // 中文默认文件
import en from "@/assets/language/en"; // 英文
import ar from '../language/ar';
import cs from '../language/cs';
import de from '../language/de';
import es from '../language/es';
import fr from '../language/fr';
import hi from '../language/hi';
import id from '../language/id';
import it from '../language/it';
import jq from '../language/jq';
import ko from '../language/ko';
import mm from '../language/mm';
import pt from '../language/pt';
import ru from '../language/ru';
import th from '../language/th';
import tr from '../language/tr';
import vi from '../language/vi';

import { createI18n } from 'vue-i18n'

const cur_lang = localStorage.getItem('Lang') ?? 'en';
// const cur_lang = localStorage.getItem('Lang') ? localStorage.getItem('Lang') : 'en';

const i18n = createI18n({
    legacy: false, // 使用组合式API
    locale: cur_lang,
    messages:{
        'en': en,
        'hk': hk,
        'ar': ar,
        'cs': cs,
        'de': de,
        'es': es,
        'fr': fr,
        'hi': hi,'id': id,'it': it,'jq': jq,'ko': ko,'mm': mm,'pt': pt,'ru': ru,'th': th,'tr': tr,'vi': vi,
    }
})

export default i18n;