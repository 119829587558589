<template>
  <div id="login" style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :rightIcon="false" />
    <div class="k-text text-center text-xl leading-40 h-80">
      <span>{{ t("app-title") }}</span>
      <span>({{ t("app-title-2") }})</span>
    </div>

    <div class="v-px-24 v-pb-40">
      <el-form
        :label-position="'top'"
        label-width="auto"
        :model="registerForm"
        ref="formRef"
      >
        <el-form-item :label="$t('public-enterEmail')" class="blue-label">
          <el-input
            v-model="registerForm.Email"
            name="Email"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('login-enterEmail')"
            autocomplete="Email"
          />
        </el-form-item>
        <el-form-item :label="$t('public-password')" class="basic-label">
          <el-input
            v-model="registerForm.Password"
            name="Password"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('login-enterPassword')"
            type="password"
            autocomplete="Password"
          />
        </el-form-item>

        <el-form-item :label="$t('public-retypePassword')" class="basic-label">
          <el-input
            v-model="registerForm.retypePassword"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('login-enterPassword')"
            type="password"
          />
        </el-form-item>

        <el-form-item :label="$t('public-inviteCode')" class="basic-label" v-if="invite">
          <el-input
            v-model="registerForm.inviteCode"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('regsiter-enterInviteCode')"
            type="number"
          />
        </el-form-item>

        <el-form-item :label="$t('public-verifyCode')" class="basic-label">
          <el-input
            v-model="registerForm.verifyCode"
            style="font-size: 3.73333vw; border-radius: 8vw;"
            :placeholder="$t('regsiter-enterVerityCode')"
            type="text"
          >
            <template #suffix>
              <canvas class="v-h-30 v-w-100 VerCodeNum" @click="CutVerCode"></canvas>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="submitForm"
            round
            class="btn-blue v-border-0"
            style="
              width: 100%;
              height: 10.66667vw;
              border-radius: 5.33333vw;
              font-size: 3.7222vw;
              margin-top: 4.66667vw
            "
          >
            {{ $t("regsiter-btn-register") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { nextTick, onMounted, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { showToast, showSuccessToast } from "/node_modules/vant";
import { drawCaptcha } from "@/assets/js/VerCode.js"
import Server from "@/Axios/index.ts";

const { t } = useI18n();
const store = useStore()
const router = useRouter();
const registerForm = ref({
  Email: "",
  Password: "",
  retypePassword: "",
  inviteCode: "",
  verifyCode: "",
});
const invite = ref(true);
const formRef = ref(null);
const VerCode = ref();

// 提示
const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 1000,
  });
};

onMounted(async() => {
    const state = await Server.get("/api/login/getConfigData");
    if (state.open_invitation === 0){
        invite.value = false;
    }else{
        invite.value = true;
    }

    nextTick(() => {
        VerCode.value = drawCaptcha('.VerCodeNum', 6)
    })
})

// 提交表单
const submitForm = async () => {
    const emailPattern = /^[a-zA-Z0-9._-]+@(gmail\.com|outlook\.com|yahoo\.com|qq\.com|163\.com|126\.com|hotmail\.com|icloud\.com)$/;

    if (!registerForm.value.Email){
        showMessage("fail", t("Res_Msg1"));
      return;
    }

    if (!emailPattern.test(registerForm.value.Email)) {
        showMessage("fail", t("Res_Msg2"));
        return;
    }

    if (!registerForm.value.Password) {
        showMessage("fail", t("Res_Msg3"));
        return;
    }

    if (registerForm.value.Password.length < 6) {
        showMessage("fail", t("Res_Msg5"));
        return;
    }

    if (registerForm.value.Password != registerForm.value.retypePassword) {
        showMessage("fail", t("Res_Msg4"));
        return;
    }

    if (!registerForm.value.verifyCode) {
        showMessage("fail", t("Res_Msg7"));
        return;
    }

    if (registerForm.value.verifyCode.toLowerCase() != VerCode.value.toLowerCase()){
        showMessage("fail", t("Res_Msg6"));
        VerCode.value = drawCaptcha('.VerCodeNum', 6)
        return;
    }

  if(registerForm.value){
      await store.dispatch("Reg", { data: { email: registerForm.value.Email, password: registerForm.value.Password, safeword:'', invite_code :registerForm.value.inviteCode} })
      showSuccessToast(t('Res_su'));
      router.push('/pages/login/loginIndex')
  }
};

// 表单验证
const validateForm = () => {
  return new Promise((resolve, reject) => {
    if (formRef.value) {
      formRef.value.validate((valid) => {
        if (valid) {
          resolve(true);
        } else {
          reject(false);
        }
      });
    } else {
      reject(false);
    }
  });
};

// 切换验证码
function CutVerCode(){
    VerCode.value = drawCaptcha('.VerCodeNum', 6)
}
</script>

<style scoped>
:deep(.el-input__wrapper) {
  border-radius: 5vw;
  height: 11vw;
  padding: 0 4vw;
  /* background: #f8f8f8 !important; */
  border: 0 solid;
  box-shadow: 0 0 0 0;
}

:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 0;
}

.text-container {
  font-size: 3.2vw;
  display: flex !important;
  justify-content: space-between !important;
  /* color: rgb(203 203 203 / 1); */
}

/* 为中等屏幕设置样式 */
@media (min-width: 600px) and (max-width: 1200px) {
  :deep(.el-input__wrapper) {
    margin: 3vw 0;
}
}
</style>
