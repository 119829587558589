<template>
  <div id="service" style="min-height: 100vh; flex: 1;">
    <div class="tgme_background_wrap">
      <canvas id="tgme_background" class="tgme_background default" width="50" height="50"
        data-colors="dbddbb,6ba587,d5d88d,88b884"></canvas>
      <div class="tgme_background_pattern default"></div>
    </div>

    <div class="tgme_page_wrap">
      <div class="tgme_head_wrap">
        <div class="tgme_head">
          <a href="//telegram.org/" class="tgme_head_brand">
            <i class="icofont-telegram tel-icon text-[40px]"></i>
            <span class="text-[22px] k-text px-2 pt-1 font-bold absolute">Telegram</span>
          </a>
          <a class="tgme_head_right_btn" href="//telegram.org/dl?tme=1c854f6eb09c02b335_17010746265653359033">
            {{ $t('public-btn-download') }}
          </a>
        </div>
      </div>

      <div class="tgme_body_wrap">
        <div class="tgme_page">
          <div class="tgme_page_photo">
            <a href="tg://resolve?domain=YouTube">
              <img class="tgme_page_photo_image" src="@/assets/image/youtube-logo.jpg" />
            </a>
          </div>

          <div class="tgme_page_title">
            <span dir="auto" class="k-text">Youtube Bot</span>
          </div>

          <div class="tgme_page_extra">@YouTube</div>
          <div class="tgme_page_description tgme_page_long_description k-text">
            This bot can help you find and share YouTube videos.
            <br />
            <br />
            By using this Bot you agree with
            <a href="https://www.youtube.com/t/terms">YouTube Terms of Service</a>
            .
          </div>
          <div class="tgme_page_action">
            <el-button class="tgme_action_button_new shine v-border-0" round @click="sendMsg">
              send message
            </el-button>
          </div>

          <div class="tgme_page_action tgme_action_privacy_action">
            <a class="tgme_action_button tgme_action_privacy_button" href="https://telegram.org/privacy/youtubebot">
              <span class="tgme_action_button_label">
                @YouTube Bot Privacy policy
              </span>
            </a>
          </div>

          <div class="tgme_page_additional">
            If you have <strong>Telegram</strong>, you can contact <strong>YouTube Bot</strong> right away.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
const router = useRouter();

const sendMsg = () => {
    window.location.href = 'https://lin.ee/LUWLau8'
}
</script>

<style scoped>
.tgme_background_wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.tgme_background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.tgme_background_pattern.default {
  opacity: 0.3;
  background-image: url(../../../assets/image/service-pattern.svg);
}

.tgme_background_pattern {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: overlay;
  background: center repeat;
  background-size: 420px auto;
}

.tgme_page_wrap {
  font-family: "Roboto", sans-serif;
  /* color: #000; */
}

.tgme_head_wrap {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 54px;
  padding: 10px 16px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* background: #ffffffd6; */
  -webkit-backdrop-filter: blur(25px);
  backdrop-filter: blur(25px);
  z-index: 1;
}

.tgme_head {
  display: flex;
  justify-content: space-between;
}

a {
  background: transparent;
}

.tgme_head_brand {
  display: inline-block;
  vertical-align: top;
}

a.tgme_head_right_btn {
  display: inline-block;
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  text-transform: uppercase;
  padding: 9px 16px;
  height: 34px;
  border-radius: 17px;
  text-align: center;
  transition: all 0.15s ease 0.15s;
}

.tgme_body_wrap {
  padding: 70px 16px 40px;
}

.tgme_page {
  position: relative;
  margin: 16px auto;
  padding: 32px 0;
  max-width: 400px;
  /* background: #fff; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  transition: all 0.15s ease 0.15s;
}

.tgme_page_photo {
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 16px;
}

.tgme_page_photo_image {
  width: 122px;
  height: 122px;
  border-radius: 61px;
}

.tgme_page_title {
  font-size: 26px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
  max-width: 340px;
  padding: 0 10px;
  margin: 0 auto;
  /* color: #000; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.tgme_page_extra {
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  padding: 6px 16px 0;
  color: #7d7f81;
}

.tgme_page_description {
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  margin: 10px 16px 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tgme_page_long_description {
  max-height: none;
  -webkit-line-clamp: unset;
}

.tgme_page a {
  color: #2481cc;
}

.tgme_page_action {
  text-align: center;
  margin-top: 24px;
  line-height: 0;
}

.tgme_action_button_new {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  color: #fff;
  border-radius: 22px;
  overflow: hidden;
  display: inline-block;
  padding: 13px 24px;
  height: 42px;
  text-transform: uppercase;
  vertical-align: top;
  background-color: #2481cc;
}

.shine {
  background-image: linear-gradient(270deg,
      rgba(100, 181, 239, 0) 48.44%,
      #64b5ef 75.52%,
      rgba(100, 181, 239, 0) 100%);
  background-repeat: no-repeat;
  animation: bg-move linear 5s infinite;
}

.tgme_page_action {
  text-align: center;
  margin-top: 24px;
  line-height: 0;
}

.tgme_action_privacy_action {
  margin-top: 12px;
}

a.tgme_action_button {
  font-size: 14px;
  line-height: 17px;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  color: #FFF;
  background: #5dc390;
  border-radius: 22px;
  overflow: hidden;
  display: inline-block;
  padding: 13px 24px;
  height: 42px;
  text-transform: uppercase;
  vertical-align: top;
}

.tgme_action_privacy_action a.tgme_action_privacy_button {
  color: #2481cc;
  background: none;
  border: 0;
  padding: 13px 25px 11px;
  font-size: 12px;
}

.tgme_page_additional {
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  padding: 0 16px;
  margin: 20px 0 0;
  margin-top: 24px;
  color: #7d7f81;
}


@keyframes bg-move {
  0% {
    background-position: -500px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

@media (max-width: 480px) {
  .tgme_page {
    margin-top: 0;
  }

  .tgme_page_photo_image {
    width: 110px;
    height: 110px;
    border-radius: 55px;
  }
}
</style>
