<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar
      :leftIcon="true"
      :headerTitle="$t('wallet-bind-bank')"
      :iconName="'Plus'"
      :handleClick="bindBank"
    />

    <div class="list">
      <div class="" v-for="(item, index) in bankCardList" :key="index">
        <bankCardItem :items="item" @delete-wallet="deleteWallet" />
      </div>
      <div class="v-h-full" v-if="bankCardList.length == 0">
        <noRecord />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { store } from "@/assets/store/data.js";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import bankCardItem from "@/pages/components/mine/wallet/bindPages/bankCardItem.vue";
import noRecord from "@/pages/components/common/noRecord.vue";
import Server from "@/Axios/index.ts";

const { t } = useI18n();
const router = useRouter();
// const bankCardList = store.value.bankCardList;
const bankCardList = ref([]);

const bindBank = () => {
  // router.push({ name: "AddBank" });
  router.push({ name: "WithdrawType", query: { type: "1" } });
};

const deleteWallet = async (id) => {
  try {
    await Server.post("/api/wallet/deleteWallet", { id: parseInt(id) });
    const response = await Server.get("/api/wallet/getWalletList", {
      params: { type: "bank" },
    });
    bankCardList.value = response || [];
  } catch (error) {
    console.error(error);
  }
};

onBeforeMount(async () => {
  const response = await Server.get("/api/wallet/getWalletList", {
    params: { type: "bank" },
  });
  bankCardList.value = response || [];
});
</script>

<style scoped>
.list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 1.33333vw 3.2vw;
  margin-top: 1.06667vw;
  border-radius: 2.66667vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16vw;
}
</style>
