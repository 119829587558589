// API地址
// api.hzwh888.vip
const apiUrl = 'https://api.hzwh888.vip/';
// 域名
const Domain = '//localhost:8080/socket';
// 周期
const Area_Tras = 7;
// 间隔
const Area_interval = 30;
// 间隔单位
const Area_Unit = 'minute';

const App_Url = "https://fbs.exchanee.com/#/"

const Pc_Url = 'https://fbs.exchanee.com/pc'

export const ServiceUrl = 'https://chatlink.wchatlink.com/widget/standalone.html?eid=2c4245e8340a6ac3537c53c778c58e1b&language=en'

export default {
    apiUrl,Domain,Area_Tras,Area_interval,Area_Unit,App_Url,Pc_Url
}
