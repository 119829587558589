<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :headerTitle="$t('app-title-language')" />

    <div id="list">
      <div class="list-container">
        <div
          v-for="item in languageList"
          :key="item"
          @click="selectLanguage(item)"
          class=""
        >
          <el-card
            style="
              width: 100%;
              margin-bottom: 10px;
              border-radius: 24px;
              border: 0;
            "
            shadow="never"
            class="list-main flex items-center v-h-60 v-mt-10 base-shadow"
            :class="{ 'selected': item.code === selectedLanguageCode }"
          >
            <img :src="item.image" class="v-w-26 v-h-18 v-mr-20" />
            <span>{{ item.title }}</span>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import topNavBar from "@/pages/components/common/topNavBar.vue";

const router = useRouter();
const { locale } = useI18n();
const store = useStore();

const languageList = ref([
  {
    title: "繁體中文",
    image: require("@/assets/image/lang/lang-cn-tw.png"),
    code: "hk",
  },
  {
    title: "English",
      image: require("@/assets/image/lang/lang-en.png"),
    code: "en",
  },
    {
        title: "日本語",
        image: require("@/assets/image/lang/jq.png"),
        code: "jq",
    },
    {
        title: "한국어",
        image: require("@/assets/image/lang/ko.png"),
        code: "ko",
    },
    {
        title: "ไทย",
        image: require("@/assets/image/lang/th.png"),
        code: "th",
    },
    {
        title: "Tiếng Việt",
        image: require("@/assets/image/lang/tv.png"),
        code: "vi",
    },
    {
        title: "français",
        image: require("@/assets/image/lang/fr.png"),
        code: "fr",
    },
    {
        title: "Deutsche",
        image: require("@/assets/image/lang/de.png"),
        code: "de",
    },
    {
        title: "Русский язык",
        image: require("@/assets/image/lang/ru.png"),
        code: "ru",
    },
    {
        title: "Español",
        image: require("@/assets/image/lang/es.png"),
        code: "es",
    },
    {
        title: "Português",
        image: require("@/assets/image/lang/pt.png"),
        code: "pt",
    },
    {
        title: "Italiano",
        image: require("@/assets/image/lang/it.png"),
        code: "it",
    },
    {
        title: "عربي",
        image: require("@/assets/image/lang/ar.png"),
        code: "ar",
    },
    {
        title: "Türkçe",
        image: require("@/assets/image/lang/tr.png"),
        code: "tr",
    },
    {
        title: "Indonesia",
        image: require("@/assets/image/lang/id.png"),
        code: "id",
    },
    {
        title: "မြန်မာ",
        image: require("@/assets/image/lang/mm.png"),
        code: "mm",
    },
    {
        title: "हिंदी",
        image: require("@/assets/image/lang/hi.png"),
        code: "hi",
    },
    {
        title: "čeština",
        image: require("@/assets/image/lang/cs.png"),
        code: "cs",
    },
]);

const selectedLanguageCode = ref(
  !localStorage.getItem("Lang") ? "en" : localStorage.getItem("Lang")
);

const selectLanguage = (language) => {
  selectedLanguageCode.value = language.code;
  updateLanguage(language);
};

const updateLanguage = (language) => {
    const selectedLang = languageList.value.find(
        (item) => item.title === language.title
    )?.code

    if (selectedLang) {
        locale.value = selectedLang;
        localStorage.setItem("Lang", selectedLang);
        setTimeout(() => {
            router.go(-1)
        }, 600);
    } else {
        console.error("Language not found");
    }
};

const goBack = () => {
  router.go(-1);
};
</script>

<style scoped>
.list-container {
  padding-left: 2.66667vw !important;
  padding-right: 2.66667vw !important;
}

:deep(.el-card__body) {
  display: flex;
  align-content: center;
}

/* .selected {
  background-color: #0166fc;
  color: white;
} */
</style>
