<template>
    <div style="min-height: 100vh; flex: 1">
        <topNavBar :leftIcon="true" :headerTitle="$t('title-detail')" />
        <div>
            <div class="v-p-10 break-all k-text">
                <div>
                    <p>{{ news.title }}</p>
                    <img :src="imageUrl" />
                    <p v-html="decodedHtml(news.content)"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import { useStore } from "vuex";

const store = useStore();
const props = defineProps({
  id: String,
});

const { t } = useI18n();
const router = useRouter();
const news = ref({});

const fetchNewData = async() => {
    news.value = await store.dispatch('GetNewDetail',{ data:{ id : props.id } })
};

onMounted(async() => {
    await fetchNewData();
});

const decodedHtml = (html) => {
    // 将实体解码为 HTML
    const parser = new DOMParser();
    const decodedString = parser.parseFromString(html, "text/html").body.textContent;
    return decodedString;
}
</script>

<style scoped></style>
