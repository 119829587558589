<template>
  <div :style="[`background:` + headerBg]" id="header">
    <el-page-header style="padding: 0 3.55vw; height: 12.26667vw;">
      <template #icon>
        <el-icon
          v-if="leftIcon"
          :size="50"
          :style="[`width: 5vw; height: 12.26667vw; color:` + iconColor]"
          @click="goBack"
          ><ArrowLeft
        /></el-icon>
        <div
          v-else-if="leftLanguageIcon"
          style="
            width: 8vw;
            height: 12.26667vw;
            right: 0;
            display: flex;
            align-items: center;
          "
          @click="openLanguage"
        >
          <img :src="currentLang(lang)" style="width: 7vw; height: 4vw" />
        </div>
        <el-icon
          v-else
          :style="[`width: 5vw; height: 12.26667vw; color:` + iconColor]"
        ></el-icon>
      </template>
      <template #title>
        <span class=""></span>
      </template>
      <template #content>
        <span
          class="text-center leading-40 v-text-18 v-h-40 font-bold"
          :style="[
            `flex: 1; color:` +
              titleColor,
          ]"
          >{{ headerTitle }}</span
        >
      </template>

      <template #extra>
        <div
        class="rightIcon"
          v-if="rightIcon"
          style="
            font-size: 5vw;
            right: 0;
            height: 12.26667vw;
            display: flex;
            align-items: center;
            align-content: center;
          "
          @click="handleClick"
        >
          <el-icon :style="rightIconStyle">
            <component :is="iconName" />
          </el-icon>
        </div>
        <div
          v-else
          style="
           width: 8vw;
            height: 12.26667vw;
            right: 0;
            display: flex;
            align-items: center;
          "
          @click="openLanguage"
        >
          <img :src="currentLang(lang)" style="width: 7vw; height: 4vw" />
        </div>
      </template>
    </el-page-header>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const props = defineProps({
  headerBg: {
    type: String,
    default: "#fff",
  },
  headerTitle: {
    type: String,
    default: "",
  },
  titleColor: {
    type: String,
    default: "#333",
  },
  showNavTabbar: {
    type: Boolean,
    default: true,
  },
  leftIcon: {
    type: Boolean,
    default: false,
  },
  iconColor: {
    type: String,
    default: "#333",
  },
  rightIcon: {
    type: Boolean,
    default: true,
  },
  rightIconStyle: {
    type: String,
    default: ''
  },
  leftLanguageIcon: {
    type: Boolean,
    default: false,
  },
  iconName: {
    type: String,
    default: null,
  },
  handleClick: {
    type: Function,
    default: null,
  },
});

function goBack() {
  router.go(-1);
}

function openLanguage() {
  router.push("/pages/components/mine/languageSelect");
}
const lang = ref(
  !localStorage.getItem("Lang") ? "en" : localStorage.getItem("Lang")
);

function currentLang(lang) {
  switch (lang) {
    case "hk":
      return require("@/assets/image/lang/lang-cn-tw.png");
    case "en":
      return require("@/assets/image/lang/lang-en.png");
    case "jq":
      return require("@/assets/image/lang/jq.png");
    case "ko":
          return require("@/assets/image/lang/ko.png");
    case "th":
      return require("@/assets/image/lang/th.png");
    case "vi":
          return require("@/assets/image/lang/tv.png");
    case "fr":
        return require("@/assets/image/lang/fr.png");
    case "de":
      return require("@/assets/image/lang/de.png");
    case "ru":
      return require("@/assets/image/lang/ru.png");
    case "es":
      return require("@/assets/image/lang/es.png");
    case "pt":
      return require("@/assets/image/lang/pt.png");
    case "it":
        return require("@/assets/image/lang/it.png");
    case "ar":
        return require("@/assets/image/lang/ar.png");
    case "tr":
        return require("@/assets/image/lang/tr.png");
    case "id":
        return require("@/assets/image/lang/id.png");
    case "mm":
        return require("@/assets/image/lang/mm.png");
    case "hi":
        return require("@/assets/image/lang/hi.png");
    case "cs":
        return require("@/assets/image/lang/cs.png");
    default:
      return require("@/assets/image/lang/lang-en.png"); // Default to English if no match
  }
}
</script>

<style scoped>
:deep(.el-divider.el-divider--vertical) {
  display: none;
}

:deep(.el-page-header__header) {
  width: 100%;
}

:deep(.el-page-header__content) {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

:deep(.el-page-header__left) {
  width: 100%;
  margin-right: 0 !important;
}
</style>
