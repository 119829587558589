import { reactive, ref } from 'vue';
import i18n from '@/assets/js/i18n';

export const store = ref({
    newsList: [
        {
            id: 1,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
            // image: require('@/assets/image/news-img_1.jpg'),
        },
        {
            id: 2,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "yoyoyoyoyoyoyyyy",
            time: "2024-03-06 00:00:00",
            image: "news-img_2.png",
        },
        {
            id: 3,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
        {
            id: 4,
            title: "GB News posts £42m loss but grows online audience",
            desc: "GB News posts £42m loss but grows online audience",
            content: "GB News posts £42m loss but grows online audience",
            time: "2024-03-06 00:00:00",
            image: "news-img_1.jpg",
        },
    ],

    bankCardList: [
        {
            bankName: 'Public Bank',
            bankAddress: 'Subang Jaya, Petaling Jaya.',
            bankSwift: 'ABCDEF',
            bankPayee: 'Roy',
            bankPayeeAcc: '6161112222'
        },
        // {
        //     bankName: 'Public Bank',
        //     bankAddress: 'Subang Jaya, Petaling Jaya.',
        //     bankSwift: 'ABCDEF',
        //     bankPayee: 'Roy',
        //     bankPayeeAcc: '6161112222'
        // }
    ],
    
    bankCurrencyList: [
        {
            id: 1,
            currency: 'ETH -- ERC20',
            walletAddress: '111111'
        },
        {
            id: 2,
            currency: 'ETH -- ERC20',
            walletAddress: '3377788'
        },
    ],

    coinList: [
        { text: "USDT", value: "USDT" },
        { text: "aaa", value: "aaa" }

    ],

    currencyOptions: [
        {
            text: 'BTC-OMNI',
            value: 'BTC-OMNI'
        },
        {
            text: 'ETH-ERC20',
            value: 'ETH-ERC20'
        },
    ],

    depositRecords: [
        {
            rechargeAmount: '6000',
            type: i18n.global.t('deposit-digitai-currency'),
            unit: 'USDT',
            status: i18n.global.t('public-text-completed'),
            date: '2024-06-26 11:15:34'
        },
        {
            rechargeAmount: '400',
            type: i18n.global.t('deposit-digitai-currency'),
            unit: 'USDT',
            status: i18n.global.t('public-text-invalid'),
            date: '2024-06-26 10:15:34'
        },
        {
            rechargeAmount: '800',
            type: i18n.global.t('deposit-digitai-currency'),
            unit: 'USDT',
            status: i18n.global.t('public-text-unchecked'),
            date: '2024-06-26 16:15:34'
        },
        {
            rechargeAmount: '6000',
            type: i18n.global.t('deposit-bank-card'),
            unit: 'USDT',
            status: i18n.global.t('public-text-completed'),
            date: '2024-06-26 11:15:34'
        }
    ],

    withdrawRecords: [
        {
            withdrawAmount: '10.00',
            numofReceipt: '48.50',
            unit: 'USD',
            handlingFee: '0.30',
            status: 'Success',
            date: '2024-04-19 06:14:14',
            payeeAcc: '2',
            remark: '',
            type: i18n.global.t('deposit-bank-card')
        },
        {
            withdrawAmount: '20.00',
            numofReceipt: '48.50',
            unit: 'USD',
            handlingFee: '0.30',
            status: 'Success',
            date: '2024-04-19 06:14:14',
            payeeAcc: '2',
            remark: '',
            type: i18n.global.t('deposit-digitai-currency')
        },
        {
            withdrawAmount: '50.00',
            numofReceipt: '48.50',
            unit: 'BTC',
            handlingFee: '0.30',
            status: 'Success',
            date: '2024-04-19 06:14:14',
            payeeAcc: '2',
            remark: '',
            type: i18n.global.t('deposit-digitai-currency')
        },
    ],

    //status: 0=holding, 1=pending, 2=history, 3=quickTrade
    holdingList: [
        {
            code: 'GBPUSD',
            originPrice: '1.0005',
            updatePrice: '1.2929',
            orderId: '366640',
            createAt: '2024-07-02 06:26:14',
            updatedAt: '2024-07-02 06:28:14',
            tradeStatus: i18n.global.t('public-text-sell'),
            quantity: '1105',
            factProfits: '-805',
            status: 1,
            margin: '110500',
            handlingFee: '11050',
            targetProfitPrice: 60075,
            targetLossPrice: 59996.99,
        },
        {
            code: 'GBPUSD',
            originPrice: '1.3495',
            updatePrice: '1.2929',
            orderId: '32240',
            createAt: '2024-07-02 06:26:14',
            updatedAt: '2024-07-02 06:28:14',
            tradeStatus: i18n.global.t('public-text-buy'),
            quantity: '2225',
            factProfits: '99905',
            status: 0,
            margin: '222500',
            handlingFee: '22250',
            targetProfitPrice: 60075,
            targetLossPrice: 59996.99,
        },
        {
            code: 'GBPUSD',
            originPrice: '1.29975',
            updatePrice: '1.78829',
            orderId: '35670',
            createAt: '2024-07-02 06:26:14',
            updatedAt: '2024-07-02 06:28:14',
            tradeStatus: i18n.global.t('public-text-buy'),
            quantity: '105',
            factProfits: '7785',
            status: 0,
            margin: '778500',
            handlingFee: '77850',
            targetProfitPrice: 60075,
            targetLossPrice: 59996.99,
        },
        {
            code: 'GBPUSD',
            originPrice: '1.24077',
            updatePrice: '1.24659',
            orderId: '30900',
            createAt: '2024-07-02 06:26:14',
            updatedAt: '2024-07-02 06:28:14',
            tradeStatus: i18n.global.t('public-text-buy'),
            quantity: '445',
            factProfits: '-86085',
            status: 0,
            margin: '110500',
            handlingFee: '11050',
            targetProfitPrice: 60075,
            targetLossPrice: 59996.99,
        },
        {
            code: 'GBPUSD',
            originPrice: '1.12375',
            updatePrice: '1.56229',
            orderId: '31110',
            createAt: '2024-07-02 06:26:14',
            updatedAt: '2024-07-02 06:28:14',
            tradeStatus: i18n.global.t('public-text-sell'),
            quantity: '1105',
            factProfits: '86995',
            status: 2,
            margin: '199990',
            handlingFee: '19880',
            targetProfitPrice: 60075,
            targetLossPrice: 59996.99,
        },
        {
            code: 'AAABBB',
            originPrice: '1.0005',
            updatePrice: '1.2929',
            orderId: '366640',
            createAt: '2024-07-02 06:26:14',
            updatedAt: '2024-07-02 06:28:14',
            tradeStatus: i18n.global.t('public-text-sell'),
            quantity: '1105',
            factProfits: '-805',
            status: 3,
            margin: '110500',
            handlingFee: '11050',
            targetProfitPrice: 60075,
            targetLossPrice: 59996.99,
        },
    ],

    periodList: [
        { text: '60s', value: '60s' },
        { text: '90s', value: '90s' },
        { text: '3min', value: '3min' },
        { text: '6min', value: '6min' },
        { text: '1day', value: '1day' },
        { text: '3day', value: '3day' },
        { text: '7day', value: '7day' },
    ],
    
    financialRecords: [
        {
            quantity: '-21980000000',
            time: '2024-07-14 18:56:43',
            type: '',
            memo: '衍生品爆仓',
        },
        {
            quantity: '-21980000000',
            time: '2024-07-14 18:56:43',
            type: '',
            memo: '衍生品保证金',
        },
    ],

    multiplierList: [
        {
            text: '100',
            value: '100'
        },
        {
            text: '400',
            value: '400'
        }
    ]

})
