<template>
  <div id="login" style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :headerTitle="$t('login-forgotPassword')" />
    <div class="k-text text-center text-xl leading-40 h-80">
      <span>{{ t("app-title") }}</span>
      <span>({{ t("app-title-2") }})</span>
    </div>

    <div class="v-px-24 v-pb-40">
      <el-form
        :label-position="'top'"
        label-width="auto"
        :model="forgetPasswordForm"
      >
        <el-form-item :label="$t('public-enterEmail')" class="blue-label">
          <el-input
            v-model="forgetPasswordForm.email"
            name="email"
            style="font-size: 3.73333vw; border-radius: 5.33333vw;"
            :placeholder="$t('login-enterEmail')"
            autocomplete="email"
          />
        </el-form-item>
        <el-form-item :label="$t('public-password')" class="basic-label">
          <el-input
            v-model="forgetPasswordForm.password"
            style="font-size: 3.73333vw; border-radius: 5.33333vw;"
            :placeholder="$t('login-enterPassword')"
            type="password"
          />
        </el-form-item>
        <el-form-item :label="$t('public-retypePassword')" class="basic-label">
          <el-input
            v-model="forgetPasswordForm.retypePassword"
            style="font-size: 3.73333vw; border-radius: 5.33333vw;"
            :placeholder="$t('login-enterPassword')"
            type="password"
          />
        </el-form-item>

        <el-form-item :label="$t('public-verifyCode')" class="basic-label">
          <el-input
            v-model="forgetPasswordForm.verifyCode"
            style="font-size: 3.73333vw; border-radius: 5.33333vw;"
            :placeholder="$t('regsiter-enterVerityCode')"
            type="number"
          >
            <template #suffix>
              <el-button
                type="primary"
                class="v-border-0"
                :class="countdown > 0 ? 'btn-blue-disabled' : 'btn-blue'"
                style="
                  position: absolute;
                  right: 0;
                  font-size: 3.73333vw;
                  border-radius: 5.33333vw;
                  height: 100%;
                  width: 23.66667vw;
                "
                :disabled="countdown > 0"
                @click="sendCode"
                >{{
                  countdown > 0 ? `${countdown}s` : $t("public-btn-send")
                }}</el-button
              >
            </template>
          </el-input>
        </el-form-item>

        <el-form-item>
          <el-button
            @click="submitForm"
            round
            type="primary"
            class="btn-blue v-border-0"
            style="
              width: 100%;
              height: 10.66667vw;
              border-radius: 5.33333vw;
              font-size: 3.7222vw;
              margin-top: 4.66667vw;
            "
          >
            {{ $t("public-btn-resetPassword") }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import { showToast } from "/node_modules/vant";

const { t } = useI18n();
const forgetPasswordForm = reactive({
  email: "",
  password: "",
  retypePassword: "",
  verifyCode: "",
});

let countdown = ref(0);
const sendCode = () => {
  if (countdown.value === 0) {
    countdown.value = 59; // 59 seconds countdown
    const timer = setInterval(() => {
      countdown.value--;
      if (countdown.value === 0) {
        clearInterval(timer);
      }
    }, 1000);
  }
  showToast({
    message: t("systemHint-code_send_succesful"),
    icon: "success",
  });
};

const showMessage = (icon, message) => {
  showToast({
    type: icon.toLowerCase(),
    message,
    duration: 1000,
  });
};

const submitForm = () => {
  const { registerEmail, registerPassword, retypePassword, verifyCode } =
    forgetPasswordForm;
  // Validate if all fields are filled
  if (!registerEmail || !registerPassword || !retypePassword || !verifyCode) {
    showMessage("fail", t("systemHint-incorrectVerityCode"));
    return;
  }

  // Validate if passwords match
  if (registerPassword !== retypePassword) {
    showMessage("fail", t("systemHint-passwordsDoNotMatch"));
    return;
  }

  // Assuming we have some function to validate invite and verify codes
  if (!validateVerifyCode(verifyCode)) {
    showMessage("fail", t("systemHint-incorrectVerityCode"));
    return;
  }
  showMessage("success", t("login-enterPassword"));
  setTimeout(() => {
    router.push("/");
  }, 2000);
};
</script>

<style scoped>
:deep(.el-input__wrapper) {
  border-radius: 5.33333vw;
  height: 11vw;
  padding: 0 4vw;
  /* background: #f8f8f8 !important; */
  border: 0 solid;
  box-shadow: 0 0 0 0;
}

:deep(.el-input__wrapper:hover) {
  box-shadow: 0 0 0 0;
}
:deep(.el-input__wrapper.is-focus) {
  box-shadow: 0 0 0 0;
}

.labelText {
  font-size: 16px;
}

.text-container {
  font-size: 3.2vw;
  display: flex !important;
  justify-content: space-between !important;
  /* color: rgb(203 203 203 / 1); */
}

/* 为中等屏幕设置样式 */
@media (min-width: 600px) and (max-width: 1200px) {
  :deep(.el-input__wrapper) {
    margin: 3vw 0;
  }
}
</style>
