<template>
    <div>
        <topNavBar :leftIcon="true" :headerTitle="$t('mine-verifyName')" />
        <div v-if="status === 0" class="text-center text-xl v-mt-14" style="min-height: 90vh;">
            <i class="icofont-clock-time text-kline-up" style="font-size: 7.8rem"></i>
            <div class="pt-2 k-text">{{ $t("verify-infoAuth") }}</div>
        </div>
        <div v-if="status === 1" class="text-center text-xl v-mt-14" style="min-height: 90vh;">
            <i class="icofont-verification-check" style="color: #2cc63e; font-size: 7rem"></i>
            <div class="pt-2 k-text">{{ $t("verify-verified") }}</div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import Server from "@/Axios/index.ts";

const status = ref(0);

onMounted(async () => {
  try {
    const response = await Server.get("/api/user/getAuth");
    status.value = response.status;
  } catch (error) {
    console.error(error);
  }
});
</script>

<style scoped></style>
