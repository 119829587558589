<template>
  <div style="min-height: 100vh; flex: 1">
    <topNavBar :leftIcon="true" :headerTitle="$t('mine-deposit')" />
    <van-pull-refresh
      v-model="loading"
      @refresh="onRefresh"
      :pulling-text="$t('public-text-scroll_down_refresh')"
      :loosing-text="$t('public-text-loose-to-refresh')"
      :loading-text="$t('public-text-loading')"
    >
      <div class="bg-general v-mt-10 v-mx-10 v-rounded-top custom-top-shadow">
        <div class="flex text-center text-white v-p-20">
          <el-button
            class="flex-1 v-rounded-xl v-py-10 mr-1 v-border-0"
            :class="selectedTab == 1 ? 'btn-blue' : 'btn-grey_1'"
            style="height: 11vw; font-size: 3.555vw"
            size="large"
            round
            @click="setTab(1)"
            >{{ $t("deposit-digitai-currency") }}</el-button
          >
          <el-button
            class="flex-1 v-rounded-xl v-py-10 mr-1 v-border-0"
            :class="selectedTab == 2 ? 'btn-blue' : 'btn-grey_1'"
            style="height: 11vw; font-size: 3.555vw"
            size="large"
            round
            @click="setTab(2)"
            >{{ $t("deposit-bank-card") }}</el-button
          >
        </div>
      </div>
      <div>
        <component :is="curDepositTab" />
      </div>
    </van-pull-refresh>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import topNavBar from "@/pages/components/common/topNavBar.vue";
import dDigitalCurrency from "@/pages/components/deposit/components/d-digitalCur.vue";
import dBankCard from "@/pages/components/deposit/components/d-bankCard.vue";

const selectedTab = ref(2);
const setTab = (tabNumber) => {
  selectedTab.value = tabNumber;
};

const curDepositTab = computed(() => {
    return selectedTab.value === 2 ? dBankCard : dDigitalCurrency;
});

const loading = ref(false);
const onRefresh = () => {
  setTimeout(() => {
    loading.value = false;
  }, 800);
};
</script>

<style scoped>
.custom-button {
  width: 180px; /* 设置固定宽度 */
  height: 45px;
  /* padding: 20px;  */
}

:deep(.el-button + .el-button) {
  margin-left: 2px !important;
}
</style>
