<template>
  <div class="bg-general v-h-full">
    <van-empty :description="$t('public-noRecord')" />
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
const { t } = useI18n();
</script>

<style scoped>

</style>
