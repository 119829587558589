
// let fakeToken = '';
// localStorage.getItem('token') || '';

export function setFakeToken(token) {
    // fakeToken = token; // 设置假的 token
    localStorage.setItem('token', token)
}

export function removeFakeToken() {
    // fakeToken = ''; // 移除假的 token
    localStorage.removeItem('token');
}

export function getToken() {
    let fakeToken = localStorage.getItem('token')
    return fakeToken; // 返回假的 token
}